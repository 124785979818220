import { Backdrop, Checkbox, Link, Box } from '@material-ui/core';

import cookies from '@utils/cookies';

import { IEvent } from '@models/event.interface';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import CheckIcon from '@material-ui/icons/Check';
import Head from '@components/pages/eventos/InscriptionEventModal/components/Head';
import { SubscribeConference } from '@services/conferences';
import { useUser } from '@hooks/user';
import { useAuth } from '@hooks/auth';
import { useSnackbar } from '@hooks/snackbar';
import { LottieLoading } from '@components/core';
import { useTracking } from '@hooks/tracking';

import { useRouter } from 'next/router';

import {
  Dialog,
  DialogContent,
  BoxImage,
  Image,
  Row,
  ColumnEvent,
  Container,
  Title,
  Date,
  Description,
  Button,
  RowTerms,
  ColumnTerms,
  Terms,
  UncheckIcon,
  IconContainer,
  Error,
  Label,
  RowLandingURL,
} from './styles';

interface IEventDetails {
  showModal: boolean;
  onClose(): void;
  event: IEvent;
  subscribeConferenceSuccess?: (slug: string) => void;
}

const EventDetailsModal: React.FC<IEventDetails> = ({
  showModal,
  onClose,
  event,
  subscribeConferenceSuccess,
}) => {
  const { user, fetchEvents, userEvents } = useUser();
  const { showSnackbar } = useSnackbar();
  const { setShowModalLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);
  const router = useRouter();
  const { track } = useTracking();

  const formatDate = useMemo(() => {
    const initialDate = dayjs(event.startedAt);
    const endDate = dayjs(event.endedAt);

    if (initialDate.date() === endDate.date()) {
      return `Dia ${initialDate.format('DD')} de ${initialDate.format(
        'MMMM',
      )} das ${initialDate.format('HH:mm')} às ${endDate.format('HH:mm')}`;
    }

    if (initialDate.month() === endDate.month()) {
      return `De ${initialDate.format('DD')} à ${endDate.format(
        'DD',
      )} de ${initialDate.format('MMMM')} das ${initialDate.format(
        'HH:mm',
      )} às ${endDate.format('HH:mm')}`;
    }

    return `De ${initialDate.format('DD de MMMM')} à ${endDate.format(
      'DD de MMMM',
    )} das ${initialDate.format('HH:mm')} às ${endDate.format('HH:mm')}`;
  }, [event.endedAt, event.startedAt]);

  const isAlreadyRegistered = useMemo(() => {
    if (!userEvents || !event) {
      return false;
    }

    return (
      userEvents.find(userEvent => userEvent?.slug === event?.slug) !==
      undefined
    );
  }, [event, userEvents]);

  const handleCheckBoxTerms = () => {
    setChecked(!checked);
  };

  const handleSubscribeConference = async () => {
    if (checked) {
      track('Clicked in subscribe event inside modal', {
        eventName: event.name,
      });
      if (user?._id) {
        try {
          setLoading(true);
          await SubscribeConference.subscribeConference({
            eventID: event.id,
            userID: user._id,
          });
          await SubscribeConference.updateHubspotProps(user, event.slug);
          fetchEvents();
          if (subscribeConferenceSuccess) {
            subscribeConferenceSuccess(event.slug);
          } else {
            cookies.set('startseUserEventEnroll', `${user._id}_${event.slug}`);
            router.push('/eventos');
          }
          onClose();
        } catch (erro) {
          showSnackbar({
            message:
              'Não foi possível realizar a inscrição no evento. Por favor, tente novamente mais tarde',
            type: 'error',
          });
        } finally {
          setLoading(false);
        }
      } else {
        setShowModalLogin(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={showModal}
      aria-labelledby="EventDetails"
      onClose={onClose}
      BackdropComponent={Backdrop}
    >
      <DialogContent
        showBgWhite
        dividers
        style={{ WebkitOverflowScrolling: 'touch' }}
      >
        <Head onClose={onClose} />

        <Container>
          <Row>
            <BoxImage>
              <Image
                src={event.imageCard ? event.imageCard : event.platformCard}
                layout="fill"
                objectFit="cover"
              />
            </BoxImage>

            <ColumnEvent>
              <Title>{event.name.toLocaleUpperCase()}</Title>
              <Date>{formatDate}</Date>
              <Description>{event.description}</Description>
              {!isAlreadyRegistered && (
                <>
                  <ColumnTerms>
                    <RowTerms>
                      <Checkbox
                        value={checked}
                        onClick={handleCheckBoxTerms}
                        color="primary"
                        checkedIcon={
                          <IconContainer>
                            <CheckIcon style={{ color: '#FFF' }} />
                          </IconContainer>
                        }
                        icon={<UncheckIcon />}
                      />
                      <Terms>
                        Eu concordo com os
                        <Link
                          target="_blank"
                          href={`${process.env.NEXT_PUBLIC_CAMPAIGN_REGULATION_URI}/politica-de-privacidade`}
                        >
                          {` termos `}
                        </Link>
                        do {event.name}
                      </Terms>
                    </RowTerms>
                    <Box textAlign="center">
                      <Link
                        target="_blank"
                        href={`${process.env.NEXT_PUBLIC_CAMPAIGN_REGULATION_URI}/politica-do-regulamento`}
                      >
                        {` Acessar regulamento do sorteio `}
                      </Link>
                    </Box>
                    {error && (
                      <Error>
                        * É necessário concordar com os termos do evento para se
                        inscrever
                      </Error>
                    )}
                  </ColumnTerms>

                  <Button
                    onClick={() => {
                      track('Clicked in subscribe event inside modal', {
                        eventName: event.name,
                      });
                      handleSubscribeConference();
                    }}
                  >
                    RESERVAR MEU LUGAR
                  </Button>
                </>
              )}
            </ColumnEvent>
          </Row>

          {!event.detailsEventUrl && (
            <RowLandingURL>
              <Label>{`Acesse a página do evento->`}</Label>
              <Link href={event.landingUrl} target="_blank">
                {event.landingUrl}
              </Link>
            </RowLandingURL>
          )}
        </Container>
      </DialogContent>
      <LottieLoading loading={loading} />
    </Dialog>
  );
};

export default EventDetailsModal;
